/* .App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 80px;
}

.App-header {
  background-color: #222;
  height: 150px;
  padding: 20px;
  color: white;
}

.App-title {
  font-size: 1.5em;
}

.App-intro {
  font-size: large;
}

@keyframes App-logo-spin {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
} */

.list-inline{display:block;}
.list-inline li{display:inline-block;}
.list-inline li:before{content:'\2022'; margin:0 10px;}
.list-inline li:first-child:before{content: '';}


@media print {
  .breakme {page-break-inside: avoid;}
  .alwaysbreak{page-break-before: always;}
}

.stateSelectorScroll {
  height: 300px;
  overflow-y: scroll;
}

